@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
}

a {
  text-decoration: none;
}

body {
  font-family: 'Roboto';
  background-color: #F4F4FA !important;
}

.appContainer {
  margin-top: 104px;

}

.appContainerMap {
  margin-top: 120px;
  padding: 0px;
}

.iiz__hint {
  display: none;
}

@media (max-width: 600px) {
  .appContainer {
    margin-top: 80px;
  }
  .appContainerMap {
    margin-top: 80px;
  }
}